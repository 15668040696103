import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import ServiceManager from '_class/ServiceManager';
import { SET_ACTIVE_USER, SET_ACTIVE_STUDENT, SET_ACTIVE_SCHOOL, SET_REFRESHINPROGRESS_USER, SET_AUTHENTICATED_USER, GET_READER_PARAMS, GET_STUDENT_SCHOOL_DATA } from 'actions/user';
var INITIAL_STATE = {
  user: null,
  activeStudent: null,
  activeSchool: null,
  refreshInProgress: false,
  authenticated: false,
  schoolDatas: null,
  reader: null,
  isApp: window.navigator.userAgent.indexOf('android-haldor') > -1 || window.navigator.userAgent.indexOf('ios-haldor') ? true : false
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        authenticated: action.payload
      });
    case SET_REFRESHINPROGRESS_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        refreshInProgress: action.payload
      });
    case SET_ACTIVE_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action.payload
      });
    case SET_ACTIVE_STUDENT:
      var student = action.payload;
      student.services = new ServiceManager(student.tenantServices, student.schoolServices).getServices();
      return _objectSpread(_objectSpread({}, state), {}, {
        activeStudent: student
      });
    case SET_ACTIVE_SCHOOL:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeSchool: action.payload
      });
    case GET_READER_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reader: action.payload
      });
    case GET_STUDENT_SCHOOL_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        schoolDatas: action.payload
      });
    default:
      return state;
  }
}